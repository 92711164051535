import CompanyInfoCard from '../../components/company info card/company-info-card.component';
import MiniGallery from '../../components/mini gallery/mini-gallery.component';
import './about-section.styles.scss';

import { PiArrowBendLeftDownFill } from "react-icons/pi";

import { GiOpenBook } from "react-icons/gi";
import { MdOutlineRateReview } from "react-icons/md";
import { IoIosNavigate } from "react-icons/io";

import { MiniGalleryImgs } from '../../assests/data/mini gallery data/mini-gallery-data.component';

const AboutSection = () => {
  return (
    <>
    
    <div className="about-section-container">
      
      <div className='company-info-container'>
        <div className="company-info">
          <h2 className='company-info-header'>
          <span className='company-info-subheader'>about</span><br/>
          Back2Beauty Advertising
          </h2>
          <p className='company-info-summary'>
              Back2Beauty helps people in South Africa find solutions for their Beauty and Wellness needs
          </p>
        </div>
      </div>
      <div className="company-info-cards-container">
          <CompanyInfoCard
            icon={<IoIosNavigate className='card-icon'/>}
            header="Quickly find the service you need"
            text="At Back2Beauty, we've designed a simple, user-friendly website to save you time, so you can focus on what you love instead of navigating complex website ecosystems."
          />
          <CompanyInfoCard
            icon={<MdOutlineRateReview className='card-icon'/>}
            header="See what other users think of the service."
            text="Browse user reviews to discover their thoughts on services, and leave your own to share your experience and guide others."
          />
          <CompanyInfoCard
            icon={<GiOpenBook className='card-icon'/>}
            header="Know what to expect."
            text="We include comprehensive details in every advertisment, from images to social links.Ensuring you know exactly what to expect."
          />
      </div>
      <div className="ad-signup-info-container">
        <MiniGallery data={MiniGalleryImgs}/>
        <div className="ad-signup-info-text-container">
          <h2 className="ad-signup-info-text-header">WHO ARE WE?</h2><br/>
          <p className="ad-signup-info-text">
          Welcome to Back2Beauty Advertising (Pty) Ltd your go to platform for connecting with top beauty professionals and discovering quality services. We make it easy for clients to find the perfect beauty match through a user-friendly platform where salons and freelancers showcase their unique skills. With options to explore portfolios, read reviews, and compare services, you can make informed choices and enjoy an exceptional beauty experience. Join our community to elevate your beauty journey thank you for choosing Back2Beauty as your trusted partner!
          </p>
          <div className="arrow-container">
            <span className="arrow-text">SIGN UP</span>
            <PiArrowBendLeftDownFill className="curved-arrow" />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default AboutSection;
