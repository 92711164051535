import './ad-navbar.styles.scss';

import { Outlet,Link} from 'react-router-dom';
import { useState } from 'react';

import { FaBars, FaTimes } from 'react-icons/fa';

import b2bLogo from '../../assests/b2blogov6.svg';

import Button from '../../components/button/button.component';


const AdNavbar = (pageInfo) => {

    

    const [mobileNavState, setMobileNavState] = useState(false);

    const enableMobileNav = () => {
        setMobileNavState(true)
    }

    const disableMobileNav = () => {
        setMobileNavState(false)
    }

    return(
        <>
        <div className="ad-nav-section-container">

            <div className='ad-nav-container'  >
                <div className="logo-container">
                    <Link to='/'>
                        <img alt='' src={b2bLogo} className='b2b-logo'/>
                    </Link>
                </div>
                <div className='links-container'>
                <nav className={mobileNavState ? "responsive_nav ad-navbar" : "ad-navbar"}>
                        <a
                            className='ad-nav-link'
                            onClick={disableMobileNav} 
                            href="/">
                            Home
                         </a>
                        <a
                            className='ad-nav-link'
                            onClick={disableMobileNav} 
                            href="#reviews ">
                            Reviews
                         </a>
                        <a
                            className='ad-nav-link'
                            onClick={disableMobileNav} 
                            href={pageInfo.pageInfo.pageInfo.bookHref}>
                            Book Now
                         </a>
                        <Button
                            href="/#discover" 
                        >
                            Back to catalog
                         </Button>


                        <button className='ad-nav-btn ad-nav-close-btn' onClick={disableMobileNav}>
                            <FaTimes/>
                        </button>
                </nav>
                        <button className='ad-nav-btn ' onClick={enableMobileNav}>
                            <FaBars/>
                        </button>
                </div>
            </div>
        </div>

        <Outlet/>
        </>
    );
}

export default AdNavbar;
