// import KyanWyssImg from "../../service preview imgs/080824Himg4.jpg";

import CairHandleyImg from "../../../assests/advert imgs/Cair Handley/IMG_5972.jpeg";

import TarynDeKerkImg from "../../../assests/advert imgs/Taryn De Kerk/IMG_20190729_124409.jpg";

import CandiceUlyateImg from "../../../assests/advert imgs/Candice Ulyate/IMG-20240930-WA0038.jpg";

import NicoleVanSensieImg from "../../../assests/advert imgs/Nicole Van Sensie/IMG_5872.png";

import TohierahKarriemImg from "../../../assests/advert imgs/Tohierah Karriem/new profile.jpeg";

import JudithFungisaniImg from "../../../assests/advert imgs/Judith Fungisani/e6246822-e1e4-4db3-8bf0-6b514e838d07_Original.jpeg";

import YolanderPintuImg from "../../../assests/advert imgs/Yolander Pintu/big-full-body-aroma-therapy-massage-60mins.jpg";

import MilkaCoxImg from "../../../assests/advert imgs/Milka  Cox/IMG_9811.png";

import DoniraPhiriImg from "../../../assests/advert imgs/Donira Phiri/1715069996100.jpg";

import SarahMaungaImg from "../../../assests/advert imgs/Sarah Maunga/IMG-20241018-WA0016.png";

import XolaNdumndumImg from "../../../assests/advert imgs/Xola Ndumndum/IMG_3367.png";

import GizzelleBeyersImg from "../../../assests/advert imgs/Gizzelle Beyers/IMG-20241002-WA0073.jpg";

import CandiceBennettImg from "../../../assests/advert imgs/Candice Bennett/1728304389809.png";

import MelanieHendricksImg from "../../../assests/advert imgs/Melanie Hendricks/IMG_20240716_121217.png";

import NatashaPImg from "../../../assests/advert imgs/Natasha P/0f24c20e-79e1-43f1-bdb8-0f2cb2b3b673.png";

import JosehineBerylDanielsImg from "../../../assests/advert imgs/Josehine Beryl Daniels//WhatsApp Image 2024-10-31 at 16.47.33.jpeg";

import NeliaNBImg from "../../../assests/advert imgs/Nelia N B/IMG_20241017_135610.png";

import ThandieBeautyImg from "../../../assests/advert imgs/Thandie Beauty/Screenshot_20220108-173724_061835 (1).png";

import TeeTishaImg from "../../../assests/advert imgs/Tee Tisha/alan-caishan-cU53ZFBr3lk-unsplash.jpg";

import LoganMentoImg from "../../../assests/advert imgs/Logan Mento/image777.png";

import AmaliaFeroImg from "../../../assests/advert imgs/Amalia Fero/amal.png";

import CarmenVanEedenImg from "../../../assests/advert imgs/Carmen  Van Eeden/IMG-20241118-WA0023_727.jpg";

import LovenessMatondoImg from "../../../assests/advert imgs/Loveness Matondo/4bc4523d7dd3faccd34d40834b0ecdd8.png";    

import LulekaKetileImg from "../../../assests/advert imgs/Luleka Ketile/engin-akyurt-ZbzYDboN7fg-unsplash.jpg"; 

import RoseGunterImg from "../../../assests/advert imgs/Rose Gunter/4387196874.png";    

import PhindileSibandaImg from "../../../assests/advert imgs/Phindile Sibanda/inbound4401456729478902219.jpg";    

import TonyDEAndradeImg from "../../../assests/advert imgs/Tony DE Andrade/ME 10.jpg";  

import TumisoMaropengImg from "../../../assests/advert imgs/Tumiso Maropeng/D43389CB-652C-43F1-A63A-DF3AD95F1B29.jpeg";    

import VenaBeautyImg from "../../../assests/advert imgs/Vena Beauty/IMG_9478(2).png";    

import ChiquitaJonesImg from "../../../assests/advert imgs/Chiquita Jones/Chiquita Jones img 1.png";    





const shuffle = ( array) => {

        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          return array;
    }

export const AdPreviewsInfoList1= [

        {
        id: 1,
        imgAlt: "CairHandley preview Img",
        imgSrc: CairHandleyImg,
        Name: "Cair - Precision Beauty",
        Town: 'City Bowl',
        about: 'Unwind with a soothing massage tailored to ease tension and restore calm. Enjoy a personalized, peaceful escape in a serene setting.',
        pageHref: '/Cair-Precision-Beauty',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 2,
        imgAlt: "Taryn de kerk preview Img",
        imgSrc: TarynDeKerkImg,
        Name: "Taryn - Perfect Touch Therapy",
        Town: 'City Bowl',
        about: 'Elevate your well-being with therapeutic touch.My massage will leave you balance and relaxed,so come an book a tranquil session with me.',
        pageHref: 'Taryn-Perfect-Touch-Therapy',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 3,
        imgAlt: "Candice Ulyate preview Img",
        imgSrc: CandiceUlyateImg,
        Name: "Candice Ulyate",
        Town: 'Claremont',
        about: 'With five years of experience, I offer personalized massage therapy aromatherapy, deep tissue, Swedish, and exfoliating scrubs to relieve pain, reduce stress, and leave you radiant.',
        pageHref: '/Candice-Ulyate',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: '5',
        ReviewNo: 3,
        },
        {
        id: 4,
        imgAlt: "Nicole Van Sensie preview Img",
        imgSrc: NicoleVanSensieImg,
        Name: "Nicole Van sensie",
        Town: 'Claremont',
        about: 'I am a qualified hairstylist offering personalized cuts, colors, and styles. I specialize in color correction, balayage, highlights, and revitalizing hair treatments.',
        pageHref: '/Nicole-Van-sensie',
        companyType: 'Free lancer',
        Services: ['Hairstylist'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 5,
        imgAlt: "Tohierah  Karriem preview Img",
        imgSrc: TohierahKarriemImg,
        Name: "Tohierah  Karriem",
        Town: 'Mitchells Plain',
        about: 'As a certified eyelash artist, I guarantee top-notch technical skill. I’m dedicated to enhancing your natural beauty and helping you wake up feeling beautiful every day!',
        pageHref: '/Tohierah-Karriem',
        companyType: 'Free lancer',
        Services: ['Eyelash Technician'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 6,
        imgAlt: "Judith Fungisani preview Img",
        imgSrc: JudithFungisaniImg,
        Name: "Judith Fungisani",
        Town: 'Lansdowne',
        about: 'I am a somatologist with eight years of experience in laser hair removal. I enhance clients’ confidence with personalized, safe, and effective treatments that deliver lasting results',
        pageHref: '/Judith-Fungisani',
        companyType: 'Free lancer',
        Services: ['Hair Removal'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 7,
        imgAlt: "Yolander Pintu preview Img",
        imgSrc: YolanderPintuImg,
        Name: "Yolander Pintu",
        Town: 'Tulbagh',
        about: 'I have been a massage therapist for 4 years I enjoy my job and I love giving people spa experience in the comfort of their own space',
        pageHref: '/Yolander-Pintu',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', 'Skincare','Hair Removal'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 8,
        imgAlt: "Milka Cox preview Img ",
        imgSrc: MilkaCoxImg,
        Name: "Milka Cox",
        Town: 'City Bowl',
        about: 'With three years of experience, I offer a variety of beauty services to enhance your aesthetic and well-being, including hair styling, makeup, massage therapy, eyelash extensions, skincare, and nails.',
        pageHref: '/Milka-Cox',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Hair Removal','Hairstylist','Skincare','Nail Technician' ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 9,
        imgAlt: "Donira Phiri preview Img",
        imgSrc: DoniraPhiriImg,
        Name: "Donira Phiri",
        Town: 'Century City',
        about: `I’ve been a massage therapist for three years in the vibrant city of Cape Town, helping clients feel relaxed and renewed. For those too busy to visit a spa, I bring the spa experience to them. 😌🧘`,
        pageHref: '/Donira-Phiri',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Nail Technician', ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 10,
        imgAlt: "Sarah Maunga preview Img",
        imgSrc: SarahMaungaImg,
        Name: "Sarah Maunga",
        Town: 'City Bowl',
        about: `With a year of experience, I offer personalized massages, including aromatherapy, Swedish strokes, deep tissue relief, and hot stone therapy for ultimate relaxation.`,
        pageHref: '/Sarah-Maunga',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 11,
        imgAlt: "Xola Ndumndum preview Img",
        imgSrc: XolaNdumndumImg,
        Name: "Xola Ndumndum",
        Town: 'Sea Point',
        about: `With four years of experience, I offer tailored mobile massage, bringing balance and relaxation to you with a full setup of bed, towels, music, oils, and hot stones.`,
        pageHref: '/Xola-Ndumndum',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 12,
        imgAlt: "Gizzelle Beyers  preview Img",
        imgSrc: GizzelleBeyersImg,
        Name: "Gizzelle Beyers",
        Town: 'Kensington',
        about: `With over 10 years in the beauty industry, I specialize in nails and lashes at my home-based salon. I’m passionate about making clients feel their best, offering a warm and relaxing experience.`,
        pageHref: '/Gizzelle-Beyers',
        companyType: 'Free lancer',
        Services: ['Nail Technician','Eyelash Technician','Hair Removal','Skincare'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 13,
        imgAlt: "Candice Bennett preview Img",
        imgSrc: CandiceBennettImg,
        Name: "Candice Bennett",
        Town: "City Bowl",
        about: `I have been in the industry for 10years + I have a passion for massage and for pampering my clients 🙂`,
        pageHref: '/Candice-Bennett',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 14,
        imgAlt: "Melanie Hendricks preview Img",
        imgSrc: MelanieHendricksImg,
        Name: "Melanie Hendricks",
        Town: "Claremont",
        about: `I specialize in individual lash extensions, offering mink and synthetic options in classic, volume, hybrid, and mega styles. I've been lashing since 2010 and use only the highest-quality products.`,
        pageHref: '/Melanie-Hendricks',
        companyType: 'Free lancer',
        Services: ['Eyelash Technician', ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 15,
        imgAlt: "Natasha P preview Img",
        imgSrc: NatashaPImg,
        Name: "Natasha P",
        Town: "Claremont",
        about: `With over five years as a massage therapist, I specialize in Swedish, Deep Tissue, Aromatherapy, Reflexology, and Indian Head Massage for a personalized, rejuvenating experience.`,
        pageHref: '/Natasha-P',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 16,
        imgAlt: "Josehine Beryl Daniels preview Img",
        imgSrc: JosehineBerylDanielsImg ,
        Name: "Josehine Beryl Daniels",
        Town: "Crawford",
        about: `With over five years as a massage therapist, I specialize in Reflexology, Thai, Ashiatsu, and Shiatsu massage, offering tailored treatments for relaxation and well-being.`,
        pageHref: '/Josehine-Beryl-Daniels',
        companyType: 'Free lancer',
        Services: ['Massage Therapist', ],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 17,
        imgAlt: "Nelia N B preview Img",
        imgSrc: NeliaNBImg ,
        Name: "Nelia N B",
        Town: "Wynberg",
        about: `With experience in Chinese, therapeutic, Swedish, deep tissue, and sports massage, I create a relaxing environment where clients can unwind and restore balance`,
        pageHref: '/Nelia-N-B',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Skincare'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 18,
        imgAlt: "Thandie Beauty preview Img",
        imgSrc: ThandieBeautyImg ,
        Name: "Thandie Beauty",
        Town: "Crawford",
        about: `With seven years of experience as a massage therapist, I enjoy helping clients feel their best. I offer Swedish massage, deep tissue, aromatherapy, sports massage, foot reflexology, and also provide manicures and pedicures, all tailored to enhance relaxation and well-being.`,
        pageHref: '/Thandie-Beauty',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Nail Technician',],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 19,
        imgAlt: "Tee Tisha preview Img",
        imgSrc: TeeTishaImg ,
        Name: "Tee Tisha",
        Town: "Crawford",
        about: `With three years of experience in massage therapy, I enjoy making clients happy through tailored Swedish and deep tissue massages that promote relaxation and well-being.`,
        pageHref: '/Tee-Tisha',
        companyType: 'Free lancer',
        Services: ['Massage Therapist',],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 20,
        imgAlt: "Logan Mento preview Img",
        imgSrc: LoganMentoImg ,
        Name: "Logan Mento",
        Town: "Macassar",
        about: `I've been working with hair and nails since the young age of 12, and over the years, I've developed a true passion for helping women feel comfortable and confident in their own skin.`,
        pageHref: '/Logan-Mento',
        companyType: 'Free lancer',
        Services: ['Hairstylist','Nail Technician',],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 21,
        imgAlt: "Amalia Fero preview Img",
        imgSrc: AmaliaFeroImg ,
        Name: "Amalia Fero",
        Town: "Wynberg",
        about: `I have dedicated three years to the beauty industry with a focus on delivering a deeply satisfying experience for every client, ensuring they leave each treatment feeling refreshed and revitalized.`,
        pageHref: '/Amalia-Fero',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 22,
        imgAlt: "Carmen  Van Eeden preview Img",
        imgSrc: CarmenVanEedenImg,
        Name: "Carmen  Van Eeden",
        Town: "City Bowl, Constantia, Muizenberg",
        about: `Carmen specializes in Deep Tissue, Swedish, and Sports Massage. She offers personalized, equipment-free physiotherapy through call-out services to help clients relax and rejuvenate.`,
        pageHref: '/Carmen-Van-Eeden',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 23,
        imgAlt: "Loveness Matondo preview Img",
        imgSrc: LovenessMatondoImg,
        Name: "Loveness Matondo",
        Town: "Durbanville",
        about: `I've been in the beauty industry for nearly four years and truly love what I do. My passion is helping others feel confident and beautiful.`,
        pageHref: '/Loveness-Matondo',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Nail Technician','Eyelash Technician'],
        gender: 2,
        starNo: '5',
        ReviewNo: 1,
        },
        {
        id: 24,
        imgAlt: "Luleka Ketile preview Img",
        imgSrc: LulekaKetileImg,
        Name: "Luleka Ketile",
        Town: "Plattekloof",
        about: `Escape to Bliss! As a skilled massage therapist with 5 years of experience, I invite you to indulge in a rejuvenating massage experience.`,
        pageHref: '/Luleka-Ketile',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Nail Technician'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 25,
        imgAlt: "Rose Gunter preview Img",
        imgSrc: RoseGunterImg,
        Name: "Rose Gunter",
        Town: "Tokai",
        about: `Rejuvenate with Rose .Relax with tailored massages, including deep tissue, sports, and Swedish techniques. Book now to restore and feel your best!`,
        pageHref: '/Rose-Gunter',
        companyType: 'Free lancer',
        Services: ['Massage Therapist',],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 26,
        imgAlt: "Tony DE Andrade preview Img",
        imgSrc: TonyDEAndradeImg,
        Name: "Tony DE Andrade",
        Town: "Radiokop",
        about: `I've been running my business since 2017, driven by a passion for delivering exceptional results and exceeding my clients' expectations.`,
        pageHref: '/Tony-DE-Andrade',
        companyType: 'Free lancer',
        Services: ['Massage Therapist','Hair Removal'],
        gender: 1,
        starNo: 'na',
        ReviewNo: 0,
        },
        {
        id: 27,
        imgAlt: "Phindile Sibanda preview Img",
        imgSrc: PhindileSibandaImg,
        Name: "Phindile Sibanda",
        Town: "Morningside",
        about: `I’ve spent 3 years in the beauty industry, perfecting nails, massages, and eyelashes to make clients feel their best.`,
        pageHref: '/Phindile-Sibanda',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 1,
        starNo: 'na',
        ReviewNo: 0,
        },      
        {
        id: 28,
        imgAlt: "Tumiso Maropeng preview Img",
        imgSrc: TumisoMaropengImg,
        Name: "Tumiso Maropeng",
        Town: "Sea Point",
        about: `I have one year of experience in this role and found a passion for helping people relax and feel better. I believe massage therapy promotes relaxation, reduces stress, and relieves pain.`,
        pageHref: '/Tumiso-Maropeng',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },      
        {
        id: 29,
        imgAlt: "Vena Beauty preview Img",
        imgSrc: VenaBeautyImg,
        Name: "Vena Beauty",
        Town: "Pretoria",
        about: `I started my nail business last year whilst in my gap year. I am certified in biosculpture products, and believe in the brand's goal of keeping the natural nail healthy. I strive to see the look on my clients faces when their ideas come to life.`,
        pageHref: '/Vena-Beauty',
        companyType: 'Salon',
        Services: ['Nail Technician'],
        gender: 0,
        starNo: 'na',
        ReviewNo: 0,
        },      
        {
        id: 30,
        imgAlt: "Chiquita Jones preview Img",
        imgSrc: ChiquitaJonesImg,
        Name: "Chiquita Jones",
        Town: "Sea Point",
        about: `With 4 years of experience, I’m a passionate massage therapist dedicated to helping clients relax and feel their best.`,
        pageHref: '/Chiquita-Jones',
        companyType: 'Free lancer',
        Services: ['Massage Therapist'],
        gender: 2,
        starNo: 'na',
        ReviewNo: 0,
        },      
]


// const LOCATIONS_ARR = ['Any Location','Century City','City Bowl','Claremont','Constantia''Crawford','Durbanville','Kensington','Lansdowne','Macassar','Mitchells Plain','Morningside','Muizenberg','Plattekloof','Pretoria','Radiokop','Sea Point','Tokai','Tulbagh','Wynberg',]
// const SERVICES_ARR = ['Any Service', 'Eyelash Technician', 'Hair Removal', 'Hairstylist', 'Massage Therapist', 'Nail Technician', 'Skincare']

export const AdPreviewsInfoList = shuffle(AdPreviewsInfoList1)